<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix" style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="exhibit"  :userId="userId"/>
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right">
                        <a-form-model
                        :model="info"
                        ref="ruleForm"
                        :rules="rules"
                        @submit.native.prevent
                        v-if="JSON.stringify(info) != '{}'"
                        >
                            <a-form-model-item
                                label="3D"
                                :labelCol="labelCol"
                                :wrapperCol="wrapperCol"
                                prop="isThreed">
                                <a-radio-group v-model="info.isThreed">
                                <a-radio :value="1">{{ $t('user.yes') }}</a-radio>
                                <a-radio :value="0">{{ $t('user.no') }}</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item
                                :label="$t('user.picture')"
                                :labelCol="labelCol"
                                :wrapperCol="wrapperCol"
                                v-if="info.isThreed == 1"
                                >
                                <a-upload
                                name="file"
                                list-type="picture-card"
                                :show-upload-list="false"
                                :customRequest="uploadImageFile"
                                :data="{ field: 'threeds' }"
                                >
                                <a-icon :type="threedsUploadStatus ? 'loading' : 'plus'" />
                                <div class="ant-upload-text">
                                    {{ $t('user.picture-upload') }}
                                </div>
                                </a-upload>
                                <a-list size="small" bordered :data-source="info.threeds" v-if="info.threeds.length > 0">
                                <a-list-item slot="renderItem" slot-scope="item, index">
                                    <img
                                    :src="item.url"
                                    width="60px"
                                    height="60px">
                                    <div><a-input v-model="item.sort" /></div>
                                    <div class="img-remove" @click="deleteImg(item.imageId, index)" v-if="item.imageId">{{ $t('user.delete') }}</div>
                                    <div class="img-remove" @click="removeImg(index)" v-else>{{ $t('user.delete') }}</div>
                                </a-list-item>
                                </a-list>
                            </a-form-model-item>
                            <a-form-model-item
                                :wrapperCol="{ span: 24 }"
                                style="text-align: center"
                            >
                                <a-button
                                htmlType="submit"
                                type="primary"
                                :loading="state.submitBtn"
                                :disabled="state.submitBtn"
                                @click="onSubmit">{{ $t('user.save') }}</a-button>
                                <a-button style="margin-left: 8px" @click="() => $router.go(-1)">{{ $t('user.cancel') }}</a-button>
                            </a-form-model-item>
                        </a-form-model>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style lang="less" scoped>
  .thumb-item{
  position: relative;
  .img-handle{
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
      left: 0;
      top: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  img{
    width: 100px;
    height: 100px;
  }
}
.img-item{
  float: left;
  margin: 5px 10px;
  position: relative;
  img{
    width: 100px;
    height: 100px;
  }
  .delete{
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.ant-list-item img, .img-remove{
  cursor: pointer;
}
.main-content .card-right{
    width: 100%
}
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

const plainOptions = ['开启', '关闭'];
export default {
  name: 'ExbitEditThree',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        id: this.$route.params.id ? parseInt(this.$route.params.id) : '',
        info: {},
        rules: {
            isThreed: [{ required: true, message: '3D必选', trigger: ['blur', 'change'] }]
        },
        state: {
            submitBtn: false
        },
        labelCol: { lg: { span: 7 }, sm: { span: 7 } },
        wrapperCol: { lg: { span: 10 }, sm: { span: 17 } },
        threedsUploadStatus: false
    }
  },
   mounted () {
    this.formInit() 
  },
   methods: {
    onChange1(e) {
      console.log('radio1 checked', e.target.value);
    },
    formInit () {
      // 获取信息
      if (this.id) {
        this.getInfo()
      } else {
        this.$message.error('参数错误')
        this.$router.go(-1)
      }
    },
     // 编辑信息
    getInfo () {
        console.log(111)
      this.$common.fetchList('/user/product/threedimage/lists/' + this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          for (var [k, v] of Object.entries(result)) {
            if (k === 'isThreed') v = parseInt(v)
            this.$set(this.info, k, v)
          }
        } else {
          this.$message.error(data.msg)
          // 跳转到目的路由
          this.$router.go(-1)
        }
      })
    },
    // 上传图片
    uploadImageFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadImageFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          if (field === 'threeds') {
            const len = this.info[field].length
            this.info[field].push({
              url: result.url,
              sort: len + 1
            })
          } else {
            this.info[field] = result.url
          }
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    // 删除图片
    removeImg (index) {
      const len = this.info.threeds.length
      if (index <= len - 1) {
        this.info.threeds.splice(index, 1)
      }
    },
    // 提交
    onSubmit () {
      const {
        state,
        add
      } = this
      state.submitBtn = true
      // 验证表单
      this.$refs.ruleForm.validate(valid => {
        if (this.info.isThreed === 1 && this.info.threeds.length === 0) {
          this.$message.error('请上传图片')
          valid = false
        }

        if (valid) {
          add()
        } else {
          setTimeout(() => {
            state.submitBtn = false
          }, 600)
        }
      })
    },
    // 添加处理
    add () {
      this.$common.handlePost('/user/product/threedimage/handle/' + this.id, this.info)
        .then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            // 跳转到目的路由
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
          })
        })
        .finally(() => {
          this.state.submitBtn = false
        })
    },
    // 删除图片
    deleteImg (id, index) {
      this.$common.handleGet('/user/product/threedimage/delete/' + id , {}).then(res => {
        if (res.code == 200) {
          this.info.threeds.splice(index, 1)
        } else {
             this.$layer.alert(res.msg);
        }
      })
      .catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>